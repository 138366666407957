import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDuDQLJfkenvRmTxR_P3H4jh9BCL7gy90Q",
  authDomain: "nprojectstats.firebaseapp.com",
  projectId: "nprojectstats",
  storageBucket: "nprojectstats.appspot.com",
  messagingSenderId: "568689325398",
  appId: "1:568689325398:web:278abee89dadfaadc4d2f7",
  measurementId: "G-834XQN2MVY"
};

const app = initializeApp(firebaseConfig);


const analytics = getAnalytics(app)