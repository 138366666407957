import NTokenList from "./NTokenList";
import { useState } from "react";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import {
  Link
} from "react-router-dom"

function NProject() {
  return (
    <>
      <div className="absolute top-5 left-5">
        <Link to="/">
          <a className="text-gray-200 hover:text-indigo-300" href="#">
            <ArrowCircleLeftIcon />Home
          </a>
        </Link>
      </div>


      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl p-8 mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-100 sm:text-4xl">
            N Project Analysis tool
          </h2>
          <p className="mt-3 mb-4 text-xl text-gray-100 sm:mt-4">
            Sortable list of all N number project with opensea link.
          </p>
        </div>
      </div>
      <div className="bg-white overflow-hidden flex flex-col rounded-lg shadow-lg">
        <NTokenList />
      </div>

    </>
  );
}

export default NProject;