/* This example requires Tailwind CSS v2.0+ */
import { Link, BrowserRouter as Router } from "react-router-dom";
const navigation: any = [
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  return (
    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
      <div className="hidden sm:block sm:ml-6 ">
        <div className="flex space-x-4">
          {navigation.map((item: any) => (
            <Link
              key={item.name}
              to={item.to}
              className={classNames(
                item.current
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:text-white",
                "px-3 py-2 rounded-md text-md font-display font-bold"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </div> 
  );
}
