/* This example requires Tailwind CSS v2.0+ */
export default function Footer() {
  return (
    <div className="flex-1 flex items-center justify-center bg-gray-800 opacity-90 w-screen p-4  sm:items-stretch sm:justify-start">
      <p className=" text-center text-gray-100 w-screen">
        <a className="text-indigo-600 underline" href="https://twitter.com/FungibleOrNot">
          Follow us on Twitter
        </a> for updates.
      </p>
    </div>
  );
}
