import "../styles/output.css";
import Header from "../components/Header/Header";
import Footer from "../components/Header/Footer";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { Suspense } from "react";
import { RecoilRoot } from "recoil";
import { Auth0Provider } from "@auth0/auth0-react";
import Home from "../pages/Home/Home";
import NProject from "../pages/NProject/NProject";

function Main() {
  return (

    <RecoilRoot>
      <Router>
        <div className="bg-nft_tool-black min-h-screen">
          <Header />
          <div className="max-w-6xl mx-auto pb-40 px-4 sm:px-6 lg:px-8">
            <Suspense fallback={<p>

              <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
                <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
              </div>

            </p>}>
              <Switch>

                <Route path="/n" exact>
                  <NProject />
                </Route>
                <Route path="/" exact>
                  <Home />
                </Route>
              </Switch>
            </Suspense>
          </div>

          <div className="fixed bottom-0"><Footer /></div>
        </div>
      </Router>
    </RecoilRoot>
  );
}

export default Main;
