export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

export const formDataFromDict = (data: any) => {
  const formData = new FormData();
  for (let key in data) {
    let value = data[key];
    if (value instanceof FileList) {
      console.log(value[0])
      value = value[0];
    }
    formData.append(key, value);
  }
  return formData
}