import { useState } from "react";
import { Link } from "react-router-dom";
import NewsletterSubForm from "../../components/NewsletterSubForm";
import {
  BadgeCheckIcon,
  CashIcon,
  FireIcon,
  FilterIcon,
} from '@heroicons/react/outline'
import { classNames } from "../../utils";
const actions = [
  {
    title: '"N Project" NFT analyser',
    href: '/n',
    icon: FilterIcon,
    disabled: false,
    description: "Search tool to find rare gems in the N project NFT collection"
  },
  {
    title: "OpenSea Trader's assistant (In Beta)",
    href: '#',
    icon: BadgeCheckIcon,
    disabled: true,
    description: "AI Trading assistant to get an edge on Opensea.io"
  },
  {
    title: 'Wallet watcher (Coming soon)',
    href: '#',
    icon: CashIcon,
    disabled: true,
    description: "Wallet watcher and trend discovery"
  },
  {
    title: 'Fundational Text base NFT collection (Coming very soon)',
    href: '#',
    icon: FireIcon,
    disabled: true,
    description: "Building on the new model pioneered by Loot (for adventurer) we are cooking up something special. Subscribe to get notified"
  }
]

function Home() {
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  return (
    <>
      <h1 className="text-3xl mb-8 text-center font-display font-bold text-white">
        FungibleOrNot
      </h1>
      <h2 className="text-4xl italic mb-20 mt-20 text-center font-display font-medium opacity-80 text-white capitalize">
        Get an edge with our (mostly free) tools for NFT collectors and traders.
      </h2>
      <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              action.disabled  ? 'cursor-wait' : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-8 w-8" aria-hidden="true" />
              </span>
            </div>
            <div className={classNames(
              action.disabled  ? 'opacity-60 cursor-wait' : ''
            )}>
              <h3 className="text-lg font-bold">
                <a href={action.href} className={classNames(
              action.disabled  ? 'cursor-wait' : ''
            )}>
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            {action.disabled == false && (
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>

            )}
          </div>
        ))}
      </div>
      <div>
        <NewsletterSubForm />
      </div>

    </>
  );
}

export default Home;