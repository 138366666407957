import { currentPageAtom,  orderByAtom} from "../../state/atoms/tokenList"
import { useState, useMemo, useEffect } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { NTokenData } from '../../types/index';
import { Link } from "react-router-dom";
import classNames from "classnames"
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/outline'
const totalNumbers = 9990
const countPerPage = 30
const pagesCount = Math.floor(totalNumbers/countPerPage)
export default function TokenListPagination() {
  const [currentPage, setCurrentPage] = useRecoilState(currentPageAtom);
  const [orderBy, setOrderBy] = useRecoilState(orderByAtom);
  const goToPage = (event:any, page:any) => {
    event.preventDefault();
    setCurrentPage(page)
  }
  return (
      <>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            {/* <p className="text-sm text-gray-700">
              Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
              <span className="font-medium">97</span> results
            </p> */}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <a
                href="#"
                onClick={(e) => goToPage(e, currentPage -1)}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
              </a>

              <a
                href="#"
                onClick={(e) => goToPage(e, currentPage +1)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}