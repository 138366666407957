import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://fungibleornot.us5.list-manage.com/subscribe/post?u=d1b3b57d3fc4ce6a06f33c047&amp;id=553d6dfab0";


// a basic form
const CustomForm = ({ status, message, onValidated }: any) => {
  let email: any = null;
  const submit = (e:any) => {
    e.preventDefault()
    return email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });

  }

  return (
    <div className="max-w-2xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
        <span className="block">Subscribe to not miss</span>
        <span className="block">our incoming private releases</span>
      </h2>
      <div className="mt-8 max-w-sm mx-auto">
        <input
          style={{ fontSize: "2em", padding: 5 }}
          ref={node => (email = node)}
          type="email"
          id="email"
          className="shadow-sm text-lg  focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Email address"
        />
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="rounded-md">
          <button
            onClick={submit}
            className="inline-flex items-center justify-center my-10 px-5 py-3 white border border-transparent text-xl font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Get notified
          </button>
        </div>
      </div>
    </div>
  );
};
// use the render prop and your custom form
const NewsletterSubForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }: any) => (
      <div>
        {/* @ts-ignore */}
        <CustomForm
          status={status}
          message={message}
          onValidated={(formData: any) => subscribe(formData)}
          />
      </div>
    )}
  />
)

export default NewsletterSubForm
