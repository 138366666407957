

import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import Web3Service from "../../services/web3service";
import { NTokenData } from '../../types/index';
import { getFunctions, httpsCallable } from "firebase/functions";

export const retrieveTokensData = (page: number, orderBy: string, order: string, count: number, singleTokenId:any) => {

  const functions = getFunctions();
  const getData = httpsCallable(functions, 'getTokens');

  if (singleTokenId) {
    return getData({ count:1, first: singleTokenId+1, orderBy: 'id', order: 'asc' }).then((response) => response.data).catch(() => [])
  }
  return getData({ count, first: (page) * count, orderBy, order }).then((response) => response.data).catch(() => [])
};

export const singleTokenAtom = atom<number | null>({
  key: 'singleTokenId',
  default: null
});
export const orderByAtom = atom<string>({
  key: 'orderBy',
  default: 'sum'
});
export const currentPageAtom = atom<number>({
  key: 'currentPage',
  default: 1
});
export const orderAtom = atom<string>({
  key: 'order',
  default: 'desc'
});
export const pageCountAtom = atom<number>({
  key: 'pageCount',
  default: 20
});
export const tokenList: any = selector({
  key: 'tokenNumbers',
  get: async ({ get }) => {
    const currentPage = get(currentPageAtom)
    const orderBy = get(orderByAtom)
    const order = get(orderAtom)
    const pageCount = get(pageCountAtom)
    const singleTokenId = get(singleTokenAtom)
    return await retrieveTokensData(currentPage, orderBy, order, pageCount, singleTokenId)
  }
  // set: ({ get, set }: any, [currentPage, orderBy, order, pageCount]:any) => {
  //   console.log(currentPage, orderBy, order, pageCount)
  //   set(currentPageAtom, currentPage);
  //   set(orderByAtom, orderBy);
  //   set(orderAtom, order);
  //   set(pageCountAtom, pageCount);
  //   // return retrieveTokensData(currentPage, orderBy, order, pageCount)
  // }
});